const userModal = document.getElementById('userModal')
if (userModal) {
  const userDeactivateForm = userModal.querySelector('form[name="deactivate_user"]')
  const tokenInput = document.getElementById('deactivate_user__token')
  const idInput = document.getElementById('deactivate_user_id')
  userModal.addEventListener('show.bs.modal', (event) => {
    const btnData = event.relatedTarget.dataset
    userDeactivateForm.action = btnData.userDeactivatePath
    idInput.value = btnData.userId
    tokenInput.value = btnData.userDeactivateToken
    userModal.querySelectorAll('.user-name').forEach((item) => {
      console.log(item)
      console.log(btnData)
      item.innerHTML = btnData.userName
    })
  })
}
